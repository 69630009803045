export const countries = {
  DE: "Deutschland",
  AT: "Österreich",
  CH: "Schweiz",
  AF: "Afghanistan",
  EG: "Ägypten",
  AL: "Albanien",
  DZ: "Algerien",
  VI: "Amerikanische Jungferninseln",
  AS: "Amerikanisch-Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarktis",
  AG: "Antigua / Barbuda",
  GQ: "Äquatorialguinea",
  AR: "Argentinien",
  AM: "Armenien",
  AW: "Aruba",
  AZ: "Aserbaidschan",
  ET: "Äthiopien",
  AU: "Australien",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesch",
  BB: "Barbados",
  BE: "Belgien",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivien",
  BA: "Bosnien-Herzegowina",
  BW: "Botsuana",
  BV: "Bouvet Inseln",
  BR: "Brasilien",
  VG: "Britische Jungferninseln",
  IO: "Britisches Territorium im Indischen Ozean",
  BN: "Brunei Darussalam",
  BG: "Bulgarien",
  BF: "Burkina-Faso",
  BI: "Burundi",
  CL: "Chile",
  CN: "China",
  CK: "Cookinseln",
  CR: "Costa Rica",
  DK: "Dänemark",
  DM: "Dominica",
  DO: "Dominikanische Republik",
  DJ: "Dschibuti",
  EC: "Ecuador",
  SV: "El Salvador",
  CI: "Elfenbeinküste",
  ER: "Eritrea",
  EE: "Estland",
  FK: "Falklandinseln",
  FO: "Färöer",
  FJ: "Fidschi",
  FI: "Finnland",
  FR: "Frankreich",
  GF: "Französisch Guayana",
  TF: "Französische Süd- und Antarktisgebiete",
  PF: "Französisch-Polynesien",
  GA: "Gabun",
  GM: "Gambia",
  GE: "Georgien",
  GH: "Ghana",
  GI: "Gibraltar",
  GD: "Grenada",
  GR: "Griechenland",
  GL: "Grönland",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard- und McDonald-Inseln",
  HN: "Honduras",
  HK: "Hongkong",
  IN: "Indien",
  ID: "Indonesien",
  IQ: "Irak",
  IR: "Iran",
  IE: "Irland",
  IS: "Island",
  IL: "Israel",
  IT: "Italien",
  JM: "Jamaika",
  JP: "Japan",
  YE: "Jemen",
  JO: "Jordanien",
  KY: "Kaimaninseln",
  KH: "Kambodscha",
  CM: "Kamerun",
  CA: "Kanada",
  CV: "Kap Verde",
  KZ: "Kasachstan",
  KE: "Kenia",
  KG: "Kirgistan",
  KI: "Kiribati",
  CC: "Kokosinseln",
  CO: "Kolumbien",
  KM: "Komoren",
  CG: "Kongo",
  HR: "Kroatien",
  CU: "Kuba",
  KW: "Kuwait",
  LA: "Laos",
  LS: "Lesotho",
  LV: "Lettland",
  LB: "Libanon",
  LR: "Liberia",
  LY: "Libyen",
  LI: "Liechtenstein",
  LT: "Litauen",
  LU: "Luxemburg",
  MO: "Macau",
  MG: "Madagaskar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Malediven",
  ML: "Mali",
  MT: "Malta",
  MA: "Marokko",
  MH: "Marshall-Insel",
  MQ: "Martinique",
  MR: "Mauretanien",
  MU: "Mauritius",
  YT: "Mayotte",
  MK: "Mazedonien",
  MX: "Mexiko",
  FM: "Mikronesien",
  MD: "Moldau",
  MC: "Monaco",
  MN: "Mongolei",
  ME: "Montenegro",
  MS: "Montserrat",
  MZ: "Mosambik",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NC: "Neukaledonien",
  NZ: "Neuseeland",
  NI: "Nicaragua",
  NL: "Niederlande",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue-Inseln",
  KP: "Nordkorea",
  MP: "Nördliche Marianen Inseln",
  NF: "Norfolkinseln",
  NO: "Norwegen",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PA: "Panama",
  PG: "Papua-Neuguinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippinen",
  PN: "Pitcairn Inseln",
  PL: "Polen",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  CD: "Republik Kongo",
  RE: "Reunion",
  RW: "Ruanda",
  RO: "Rumänien",
  RU: "Russische Föderation",
  PM: "Saint-Pierre und Miquelon",
  SB: "Salomonen",
  ZM: "Sambia",
  SM: "San Marino",
  ST: "São Tomé und Príncipe",
  SA: "Saudi-Arabien",
  SE: "Schweden",
  SN: "Senegal",
  RS: "Serbien",
  SC: "Seschellen",
  SL: "Sierra Leone",
  ZW: "Simbabwe",
  SG: "Singapur",
  SK: "Slowakei",
  SI: "Slowenien",
  SO: "Somalia",
  ES: "Spanien",
  LK: "Sri Lanka",
  KN: "St. Christopher-Nevis-Anguilla",
  SH: "St. Helena",
  LC: "St. Lucia",
  VC: "St. Vincent",
  ZA: "Südafrika",
  SD: "Sudan",
  KR: "Südkorea",
  GS: "Südliche Sandwichinseln",
  SR: "Suriname",
  SJ: "Svalbard",
  SZ: "Swasiland",
  SY: "Syrien",
  TJ: "Tadschikistan",
  TW: "Taiwan",
  TZ: "Tansania",
  TH: "Thailand",
  TG: "Togo",
  TK: "Tokelau-Inseln",
  TO: "Tonga",
  TT: "Trinidad und Tobago",
  TD: "Tschad",
  CZ: "Tschechische Republik",
  TN: "Tunesien",
  TR: "Türkei",
  TM: "Turkmenistan",
  TC: "Turks- und Caicosinseln",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  HU: "Ungarn",
  GB: "United Kingdom",
  UM: "United States Minor Outlying Islands",
  UY: "Uruguay",
  US: "USA",
  UZ: "Usbekistan",
  VU: "Vanuatu",
  VA: "Vatikanstadt",
  VE: "Venezuela",
  AE: "Vereinigte Arabische Emirate",
  VN: "Vietnam",
  WF: "Wallis und Futuna",
  CX: "Weihnachtsinsel",
  BY: "Weißrussland",
  EH: "West Sahara",
  WS: "Westsamoa",
  CF: "Zentralafrikanische Republik",
  CY: "Zypern",
};
