import { formatDate, formatTime } from "./common/date";
import Amplify, { Auth } from "aws-amplify";
import cdkOutputs from "./cdk-outputs.json";
import { useState } from "react";

const API_URL = (cdkOutputs as any)[`${process.env.REACT_APP_STAGE}-covid-bs-Backend-Stack`][
  `${process.env.REACT_APP_STAGE}ProtectedApiGatewayEndpoint`
];

export type TestResultOptions = "positive" | "negative" | "invalid";

export type Appointment = {
  sk: string;
  firstName: string; // Vorname
  lastName: string; // Nachname
  dateOfBirth: string; // Geburtsdatum
  nationality: string; // Staatsangehörigkeit
  email: string; // E-Mail
  phone: string; // Telefon
  address: string;
  testResult?: TestResultOptions;
};

export type UpdateAppointmentEvent = {
  testResult?: TestResultOptions;
  isNoShow?: boolean;
  isCancelled?: boolean;
  isReportedToHealthCare?: boolean;
  notes?: string;
};

export function BoAppointmentDetails({ appointment }: { appointment: Appointment }) {
  const [appointmentData, setAppointmentData] = useState<Appointment>(appointment);

  const updateAppointment = async (appointment: Appointment, update: UpdateAppointmentEvent) => {
    const response = await fetch(`${API_URL}/update/bs-bro/${appointment.sk}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${await Auth.currentSession().then((data: any) => data.idToken.jwtToken)}`,
      },
      body: JSON.stringify(update),
    });

    const { item: newAppointment } = await response.json();

    console.log(newAppointment);
    setAppointmentData({ ...appointment, ...newAppointment });
  };

  const handleResult = async (result: TestResultOptions) => {
    const confirmed = window.confirm(
      `Sicher, dass das Testergbnis von ${appointmentData.firstName} ${appointmentData.lastName} ${result} ist?`
    );

    if (confirmed) {
      await updateAppointment(appointmentData, { testResult: result });
    }
  };

  const handleNoShow = () => {
    const confirmed = window.confirm(
      `Sicher, dass ${appointmentData.firstName} ${appointmentData.lastName} nicht angekommen ist?`
    );

    if (confirmed) {
      updateAppointment(appointmentData, { isNoShow: true });
    }
  };

  let colorBackgroundClass = "";

  if (appointmentData.testResult === "positive") {
    colorBackgroundClass = "bg-positive";
  } else if (appointmentData.testResult === "negative") {
    colorBackgroundClass = "bg-negative";
  } else if (appointmentData.testResult === "invalid") {
    colorBackgroundClass = "bg-invalid";
  }

  return (
    <div className={`${colorBackgroundClass} appointment`}>
      <h4>
        {formatDate(appointmentData.sk)} {formatTime(appointmentData.sk)}
      </h4>
      <h3>
        {appointmentData.firstName} {appointmentData.lastName}{" "}
        {appointmentData.testResult && `(Test ${appointmentData.testResult})`}
      </h3>
      <table>
        <tbody>
          <tr>
            <td>E-Mail-Adresse</td>
            <td>{appointmentData.email}</td>
          </tr>
          <tr>
            <td>Telefonnummer</td>
            <td>{appointmentData.phone}</td>
          </tr>
          <tr>
            <td>Staatsangehörigkeit</td>
            <td>{appointmentData.nationality}</td>
          </tr>
          <tr>
            <td>Adresse</td>
            <td>{Object.values(JSON.parse(appointmentData.address)).join(", ")}</td>
          </tr>
          <tr>
            <td>Geburtstag</td>
            <td>{new Date(appointmentData.dateOfBirth).toLocaleDateString()}</td>
          </tr>
        </tbody>
      </table>
      <h4>Testergebnis{appointmentData.testResult && `: ${appointmentData.testResult}`}</h4>
      <button onClick={() => handleResult("negative")} className="negative">
        negative
      </button>
      <button onClick={() => handleResult("positive")} className="positive">
        positive
      </button>
      <button onClick={() => handleResult("invalid")} className="invalid">
        invalid
      </button>
      <br />
      <br />
      <button onClick={() => handleNoShow()}>nicht erschienen</button>
    </div>
  );
}
