import { useEffect, useState } from "react";

export function BoHeader() {
  const [isPreProd, setIsPreProd] = useState(false);

  useEffect(() => {
    if ((window as any).location.host !== `backoffice.covid-bs.de`) {
      setIsPreProd(true);
    }
  }, []);

  return (
    <>
      {isPreProd && (
        <div className="band">
          Pre-Production: Dies ist eine Testumgebung! <br />
          Bitte nur Testdaten! Gebuchte Termine finden nicht statt!
        </div>
      )}
      <div className="header wrapper">
        <h1 className="domain">covid-bs.de Cockpit</h1>
        <h2>Corona-Testzentrum Broitzemer Straße 233, Braunschweig</h2>
      </div>
    </>
  );
}
