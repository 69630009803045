import { useEffect, useState } from "react";
import Amplify, { Auth } from "aws-amplify";
import { formatDate } from "./common/date";
import { Appointment, BoAppointmentDetails } from "./BoAppointmentDetails";
import cdkOutputs from "./cdk-outputs.json";
import { DayStats } from "./DayStats";

const API_URL = (cdkOutputs as any)[`${process.env.REACT_APP_STAGE}-covid-bs-Backend-Stack`][
  `${process.env.REACT_APP_STAGE}ProtectedApiGatewayEndpoint`
];

const last2days = new Array(3)
  .fill("")
  .map((_, i) => new Date(new Date(new Date().toISOString().substring(0, 10)).setDate(new Date().getDate() - (i + 1) * 1)));

const next14Days = new Array(14)
  .fill("")
  .map((_, i) => new Date(new Date(new Date().toISOString().substring(0, 10)).setDate(new Date().getDate() + i * 1)));

export function BoAppointmentsOverview() {
  const [days, setDays] = useState([...next14Days, ...last2days]);
  const [appointments, setAppointments] = useState([] as Appointment[]);
  const [day, setDay] = useState(new Date().toISOString().substring(0, 10));

  const fetchAppointments = async (day: string) => {
    const response = await fetch(`${API_URL}/appointments/${`bs-bro`}/${day}`, {
      headers: {
        Authorization: `Bearer ${await Auth.currentSession().then((data: any) => data.idToken.jwtToken)}`,
      },
    });
    const { appointments } = await response.json();
    setAppointments(appointments);
  };

  useEffect(() => {
    fetchAppointments(day);
  }, [day]);

  const handleDayChange = async (e: any) => {
    setDay(e.target.value.substr(0, 10));
  };

  return (
    <div className="wrapper">
      <DayStats day={day} appointments={appointments} />

      <label>
        <select id="day" name="day" onChange={handleDayChange} value={day} required>
          {days.map((date) => {
            return (
              <option value={date.toISOString().substring(0, 10)} key={date.toISOString()} className="day">{`${formatDate(
                date
              )}`}</option>
            );
          })}
        </select>
      </label>
      {appointments.map((appointment) => (
        <BoAppointmentDetails appointment={appointment} key={JSON.stringify(appointment)} />
      ))}
    </div>
  );
}
