import { Appointment } from "./BoAppointmentDetails";
import { formatDate } from "./common/date";

export function DayStats({ appointments, day }: { appointments: Appointment[], day: string }) {
  return (
    <div className="stats">
      <b>
        {appointments.length} Termine insgesamt am {formatDate(day)}
      </b>
      <span>{appointments.filter((t) => t.testResult === "negative").length} negativ</span>
      <span>{appointments.filter((t) => t.testResult === "positive").length} positiv</span>
      <span>{appointments.filter((t) => t.testResult === "invalid").length} ungültig</span>
      <span>{appointments.filter((t) => typeof t.testResult === "undefined").length} noch offen</span>
    </div>
  );
}
