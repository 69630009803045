import React from "react";
import Amplify, { Auth } from "aws-amplify";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import cdkOutputs from "./cdk-outputs.json";
import { BoAppointmentsOverview } from "./BoAppointmentsOverview";
import { BoHeader } from "./BoHeader";
import { BoNavigation } from "./BoNavigation";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { BoAppointmentForm } from "./BoAppointmentForm";

Amplify.configure({
  Auth: {
    region: "eu-west-1",
    userPoolId: (cdkOutputs as any)[`${process.env.REACT_APP_STAGE}-covid-bs-Backend-Stack`][
      `${process.env.REACT_APP_STAGE}CognitoUserPoolId`
    ],
    userPoolWebClientId: (cdkOutputs as any)[`${process.env.REACT_APP_STAGE}-covid-bs-Backend-Stack`][
      `${process.env.REACT_APP_STAGE}UserPoolWebClientId`
    ],
    mandatorySignIn: true,
    authenticationFlowType: "USER_SRP_AUTH",
  },
});

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<BoAppointmentsOverview />} />
            <Route path="/terminbuchung" element={<BoAppointmentForm />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

function Layout() {
  return (
    <>
      <BoHeader />
      <AmplifySignOut />
      <BoNavigation />
      <div className="formWrapper wrapper">
        <Outlet />
      </div>
    </>
  );
}

export default withAuthenticator(App);
