import React from "react";
import Amplify, { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { emailRegex } from "./common/validation"
import { formatDate, formatTime } from "./common/date";
import cdkOutputs from "./cdk-outputs.json";
import { countries } from "./common/countries";

const API_URL = (cdkOutputs as any)[`${process.env.REACT_APP_STAGE}-covid-bs-Backend-Stack`][
  `${process.env.REACT_APP_STAGE}ProtectedApiGatewayEndpoint`
];

export function BoAppointmentForm() {
  const [success, setSuccess] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [timeslot, setTimeslot] = React.useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // defaultValues: JSON.parse(localStorage.getItem("formData") || "{}"),
  });

  const onSubmit = async (data: any) => {
    setDisabled(true);
    if (data.saveData) {
      localStorage.setItem("formData", JSON.stringify({ ...data, timeslot: undefined }));
    } else {
      localStorage.removeItem("formData");
    }

    const location = "bs-bro";
    try {
      const response = await fetch(`${API_URL}/instant-appointment/${location}/${data.timeslot}`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await Auth.currentSession().then((data: any) => data.idToken.jwtToken)}`,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        setTimeslot(data.timeslot);
        setSuccess(true);
      }
      if (response.status === 409) {
        const message = "Fehler: Termin zwischenzeitlich gebucht. Bitte wählen Sie einen anderen Zeitpunkt.";
        alert(JSON.stringify(message));
      }
    } catch (err) {
      alert(JSON.stringify(err));
    } finally {
      setDisabled(false);
    }
  };

  if (success) {
    return (
      <div className="formWrapper wrapper">
        <h1 className="center huge">☑️</h1>
        <p className="center bold">{`${formatDate(timeslot)} ${formatTime(timeslot)}`}</p>
        <br />
        <p>Ihr Termin wurde erfolgreich gebucht. Sie erhalten eine E-Mail mit den Details zu Ihrer Buchhung.</p>
        <p>Sie können jederzeit einen weiteren Termin buchen. Aktualisieren Sie dafür einfach diese Seite.</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1>Sofort-Anmeldung zum Corona-Test</h1>
      <br />
      <label htmlFor="location">
        Ort:
        <br />
        <a
          className="inputLikeText"
          href="https://www.google.com/maps/place/Broitzemer+Str.+233,+38118+Braunschweig/"
          target="_blank"
        >
          Cut and More, Broitzemer Str. 233, 38118 Braunschweig
        </a>
      </label>
      <label htmlFor="timeslot">
        Zeitpunkt (unverändert lassen für sofortige Buchung):
        <br />
        <input
          type="text"
          defaultValue={new Date().toISOString()}
          className={errors.timeslot && "validationErrorInput"}
          id="timeslot"
          {...register("timeslot", { required: true, minLength: 16, maxLength: 24 })}
        />
        {errors.timeslot && <span className={"validationError"}>Bitte geben Sie einen Zeitpunkt an</span>}
      </label>
      <label htmlFor="firstName">
        Vorname:
        <br />
        <input
          type="text"
          className={errors.firstName && "validationErrorInput"}
          id="firstName"
          {...register("firstName", { required: true, minLength: 2, maxLength: 50 })}
        />
        {errors.firstName && <span className={"validationError"}>Bitte geben Sie einen Vornamen ein</span>}
      </label>
      <label htmlFor="lastName">
        Nachname:
        <br />
        <input
          type="text"
          className={errors.lastName && "validationErrorInput"}
          id="lastName"
          {...register("lastName", { required: true, minLength: 2, maxLength: 50 })}
        />
        {errors.lastName && <span className={"validationError"}>Bitte geben Sie einen Nachnamen ein</span>}
      </label>
      <label htmlFor="street">
        Straße &amp; Hausnummer:
        <br />
        <input
          type="text"
          className={errors.address?.street && "validationErrorInput"}
          id="street"
          {...register("address.street", { required: true, minLength: 2, maxLength: 50 })}
        />
        {errors.address?.street && <span className={"validationError"}>Bitte geben Sie Straße und Hausnummer ein</span>}
      </label>
      <label htmlFor="postalCode">
        Postleitzahl:
        <br />
        <input
          type="text"
          className={errors.address?.postalCode && "validationErrorInput"}
          id="postalCode"
          {...register("address.postalCode", { required: true, minLength: 5, maxLength: 5 })}
        />
        {errors.address?.postalCode && <span className={"validationError"}>Bitte geben Sie eine Postleitzahl ein</span>}
      </label>
      <label htmlFor="city">
        Ort:
        <br />
        <input
          type="text"
          className={errors.address?.city && "validationErrorInput"}
          id="city"
          {...register("address.city", { required: true, minLength: 2, maxLength: 50 })}
        />
        {errors.address?.city && <span className={"validationError"}>Bitte geben Sie einen Ort ein</span>}
      </label>
      <label htmlFor="nationality">
        Nationalität:
        <br />
        <select
          id="nationality"
          className={errors.nationality && "validationErrorInput"}
          {...register("nationality", { required: true, minLength: 2, maxLength: 50 })}
        >
          {Object.entries(countries).map((country) => {
            const [short, long] = country;
            return <option key={short} value={short}>{long}</option>;
          })}
        </select>
        {errors.nationality && <span className={"validationError"}>Bitte geben Sie Ihre Nationalität ein</span>}
      </label>
      <label htmlFor="dateOfBirth">
        Geburtsdatum:
        <br />
        <input
          type="date"
          className={errors.dateOfBirth && "validationErrorInput"}
          id="dateOfBirth"
          {...register("dateOfBirth", { required: true, min: "1920-01-01", max: "2018-12-31" })}
        />
        {errors.dateOfBirth && <span className={"validationError"}>Bitte geben Sie Ihr Geburtsdatum ein</span>}
      </label>
      <label htmlFor="email">
        E-Mail-Adresse:
        <br />
        <input
          type="email"
          className={errors.email && "validationErrorInput"}
          id="email"
          {...register("email", { required: true, pattern: emailRegex })}
        />
        {errors.email && <span className={"validationError"}>Bitte geben Sie eine E-Mail-Adresse ein</span>}
      </label>
      <label htmlFor="tel">
        Mobilfunknummer:
        <br />
        <input
          type="tel"
          id="tel"
          className={errors.phone && "validationErrorInput"}
          {...register("phone", { required: true })}
        />
        {errors.phone && <span className={"validationError"}>Bitte geben Sie eine Mobilfunknummer ein</span>}
      </label>
      <label htmlFor="dataProtectionAgreement">
        <input
          type="checkbox"
          className={errors.dataProtectionAgreement && "validationErrorInput"}
          id="dataProtectionAgreement"
          {...register("dataProtectionAgreement", { required: true })}
        />
        Ich akzeptiere die{" "}
        <a href="/datenschutz" target="_blank">
          Datenschutzbedingungen
        </a>
        . Mir ist bewusst, dass positive Testergebnisse dem Gesundheitsamt gemeldet werden.
        {errors.dataProtectionAgreement && (
          <span className={"validationError"}>Bitte stimmen Sie den Datenschutzbestimmungen zu</span>
        )}
      </label>
      <label htmlFor="healthy">
        <input
          type="checkbox"
          className={errors.healthy && "validationErrorInput"}
          id="healthy"
          {...register("healthy", { required: true })}
        />
        Ich bestätige, dass ich nur wenn ich keine Symptome aufweise, zum Test erscheine.
        {errors.healthy && <span className={"validationError"}>Bitte stimmen Sie zu.</span>}
      </label>
      {/* <label htmlFor="saveData">
        <input type="checkbox" id="saveData" {...register("saveData", { required: false })} />
        Für zukünftige schnelle erneute Buchung, Daten lokal auf meinem Gerät speichern.
      </label> */}
      <br />
      {/* <h3>Anzeige Ihres Testergbnisses in der Corona-Warn-App (freiwillig)</h3>
      <label htmlFor="cwaNot">
        <input type="radio" id="cwaNot" value="cwaNot" {...register("cwa")} />
        <span className="bold">Keine Daten an die Corona Warn App übermitteln.</span><br />
        Wenn Sie dies Option wählen erhalten Sie Ihr Testergebis nicht in der Corona Warn App, sondern nur per E-Mail.
      </label>
      <label htmlFor="cwaPseudonym">
        <input type="radio" id="cwaPseudonym" value="cwaPseudonym" {...register("cwa")} />
        <span className="bold">Einwilligung zur pseudonymisierten Übermittlung (nicht-namentliche Anzeige)</span><br />
        Hiermit erkläre ich mein Einverständnis zum Übermitteln meines Testergebnisses und meines pseudonymen Codes an
        das Serversystem des RKI, damit ich mein Testergebnis mit der Corona-Warn-App abrufen kann. Das Testergebnis in
        der App kann hierbei nicht als namentlicher Testnachweis verwendet werden. Mir wurden Hinweise zum Datenschutz
        ausgehändigt.
      </label>
      <label htmlFor="cwaFull">
        <input type="radio" id="cwaFull" value="cwaFull" {...register("cwa")} />
        <span className="bold">Einwilligung zur personalisierten Übermittlung (namentlicher Testnachweis)</span><br />
        Hiermit erkläre ich mein Einverständnis zum Übermitteln des Testergebnisses und meines pseudonymen Codes an das
        Serversystem des RKI, damit ich mein Testergebnis mit der Corona-Warn-App abrufen kann. Ich willige außerdem in
        die Übermittlung meines Namens und Geburtsdatums an die App ein, damit mein Testergebnis in der App als
        namentlicher Testnachweis angezeigt werden kann. Mir wurden Hinweise zum Datenschutz ausgehändigt.
      </label>
      <br /> */}
      <label htmlFor="submit">
        <input type="submit" value={disabled ? "Reservierung senden..." : "Termin reservieren"} disabled={disabled} />
      </label>
    </form>
  );
}
